#mypets /*.frame-wrapper {*/
/*    background-image: url('../img/inventorybg.png');*/
/*    background-size: 100% 100%;*/
/*    background-repeat: no-repeat;*/
/*    padding: 3rem 2rem;*/
/*    margin: 4.5rem 2rem;*/
/*    position: relative;*/
/*  }*/
#mypets .fram-sec {
    background-image: none !important;
    padding: 20px !important;
    margin: 0px !important;
}
/*.frame-wrapper {*/
/*    background-image: url("../img/myacution-back.png")!important;*/
/*    background-size: 100% 100%;*/
/*    background-repeat: no-repeat;*/
/*    height: auto;*/
/*    padding: 2rem 2rem 3rem;*/
/*    margin: 4.5rem 2rem;*/
/*    position: relative;*/
/*}*/

#mypets .frame-wrapper-pets .border-left img {
    position: absolute;
    left: -22px;
    top: -39px;
}

#mypets .frame-wrapper-pets .border-right img {
    position: absolute;
    right: -22px;
    top: -39px;
}

#mypets .frame-wrapper-pets .centerLogoDv figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    text-align: center;
    margin: 0px auto;
}


.profile-creation p {
    color: #FFFAE2;
    font-size: 14px;
}
.profile {
    padding-top: 100px;
    padding-bottom: 100px;
}
/* my character section css starts here */
.mycharacter-sec {
    padding-top: 70px;
    padding-bottom: 70px;
}
.my-char-content .player-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my-char-content .player-level{
    display: flex;
    align-items: center;
}
.mychar-wrapper .black-img-wrapper {
    border-style: solid;
    border-width: 3px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    border-image-slice: 1;
}
.my-char-content {
    color: #fff;
    margin-top: 23px;
}
.my-char-content .Pname-title,.my-char-content .Plevel-title {
    color: #E3BF4B;
    font-family: 'aniron';
    font-size: 10px;
}
.my-char-content p.player-name,.my-char-content .player-level-no {
    margin-left: 17px;
    font-family: 'aniron';
    font-size: 23px;
}
.mychar-wrapper .btn {
    color: #E3BF4B;
    background-image: linear-gradient(to right bottom, #000002, #101011, #1a191a, #232323, #2c2c2c);
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient( to right, #b88425, #c5973f, #d2a957, #e0bc6f, #edcf88, #efd28b, #f1d58f, #f3d892, #eacb80, #e1bf6f, #d9b25d, #d0a64c ) !important;
    border-image-slice: 1;
    padding: 10px 24px;
}
.mychar-wrapper {
    margin-top: 50px;
}
/*.mychar-wrapper .black-img-wrapper img {*/
/*    width: 160px;*/
/*    height: 160px;*/
/*    !*object-fit: cover;*!*/
/*    margin-top: 19px;*/
/*    margin-right: 10px;*/
/*}*/
.mychar-wrapper .black-img-wrapper img {
    width: 240px;
    height: 270px;
    object-fit: contain;
    /* margin-top: 19px; */
    /* margin-right: 10px; */
}
.mychar-wrapper .black-img-wrapper figure {margin: 0px;}

.mychar-wrapper .black-img-wrapper {
    height: 273px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    align-items: baseline;
}
.black-img-wrapper ul li {
    list-style: none;
    color: #E3BF4B;
    font-size: 9px;
    font-family: 'Poppins';
    text-align: left;
}
.black-img-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
}
.black-img-wrapper ul {
    position: absolute;
    left: 0px;
    bottom: 26%;
}
.profile-creation h3 {
    color: #E3BF4B;
    font-size: 26px;
}
/* my character section css ends here */